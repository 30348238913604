import { AppBar, Toolbar, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import nextwaveLogo from '../../assets/images/nextwave-white.png';
import homeButton from '../../assets/images/home-button.png';
import homeButtonBlue from '../../assets/images/home-button-blue.png';

///<a target="_blank" href="https://icons8.com/icon/1iF9PyJ2Thzo/home">home button</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>

const HeaderBar = (props) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [application, setApplication] = useState("");

    useEffect(() => {
        (async () => {
            await props.userApplication;

            const pathParts = location.pathname.split('/');
            const assessmentType = pathParts[1];

            const assessmentLabels = {
                'maturity-assessment': ': Resource Capability Assessment',
                'project-assessment': ': Project-Delivery Assessment',
                'platform-solution-assessment': ': Platform-Solution Assessment'
            };

            const displayLabel = assessmentLabels[assessmentType] || "";

            setApplication(displayLabel);
        })();
    }, [props, location]);

    return (
        <AppBar position="fixed" style={{ backgroundColor: 'black', paddingBottom: "18px" }}>
            <Toolbar>
                {application !== "" ? (
                    <img
                        src={`${homeButton}`}
                        alt="home"
                        style={{
                            marginLeft: "15px",
                            marginTop: "5px",
                            marginRight: "30px",
                            backgroundColor: 'black',
                            cursor: 'pointer'
                        }}
                        height="70"
                        width="70"
                        onMouseEnter={(e) => e.currentTarget.src = homeButtonBlue}
                        onMouseLeave={(e) => e.currentTarget.src = homeButton}
                        onClick={() => navigate(`/${props.activeUserId}`)}
                    />
                ) : null}
                <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                    Maturity Assessment{application}
                </Typography>
                <img
                    src={`${nextwaveLogo}`}
                    alt="nextwave"
                    style={{
                        marginLeft: "15px",
                        marginTop: "15px",
                        marginRight: "20px",
                        marginBottom: "25px",
                        paddingTop: "15px",
                        backgroundColor: 'black'
                    }}
                    height="85"
                    width="260"
                />
            </Toolbar>
        </AppBar>
    );
};

export default HeaderBar;
