import { Grid, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import rolesConfig from '../../data/roles.json';

const Welcome = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const applications = ["project-assessment", "platform-solution-assessment", "maturity-assessment"];

    const assessmentLabels = {
        'maturity-assessment': 'Resource Capability Assessment',
        'project-assessment': 'Project-Delivery Assessment',
        'platform-solution-assessment': 'Platform-Solution Assessment'
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
              await props.fetchUserActiveApplicationsForSpecificUser(params.userId);
              await props.fetchUserCompleteApplicationsForSpecificUser(params.userId);
              await props.fetchUserRegisteredApplicationsForSpecificUser(params.userId);
            } catch (error) {
              console.error('Error fetching user data:', error);
            }
          };
          fetchData();

    }, [params.userId, location]);

    const [userDetails, setUserDetails] = useState(null);
    const [buttonCLicked, setButtonClicked] = useState(false);


    async function fetchAndLogUserDetails(props, userId) {
        const userDetails = {};
        try {
          userDetails.activeComponent = await props.fetchUserActiveComponent(userId);
          // console.log("Fetched User Active Component:", userDetails.activeComponent);

          // Fetch user active assessment
          userDetails.activeAssessment = await props.fetchUserActiveAssessment(userId);
          // console.log("Fetched User Active Assessment:", userDetails.activeAssessment);

          // Fetch user active criteria
          if (props.userApplication === 'maturity-assessment') {
            userDetails.activeCriteria = await props.fetchUserActiveCriteria(userId);
            // console.log("Fetched User Active Criteria:", userDetails.activeCriteria);

            userDetails.activeRole = await props.fetchUserActiveRole(userId);
            // console.log("Fetched User Active Role:", userDetails.activeRole);
          } else {
            userDetails.activeCriteria = 0
            userDetails.activeRole = "None"


          }

        } catch (error) {
          console.error("Error fetching user details:", error);
        }

        return userDetails;
      }

      useEffect(() => {
        const fetchDetails = async () => {
          if (buttonCLicked) {
            const userDetails = await fetchAndLogUserDetails(props, params.userId);
            setUserDetails(userDetails);
            setButtonClicked(false);
          }
        };

        fetchDetails();
      }, [props.userApplication, buttonCLicked])

    useEffect(async () => {
        // Use userDetails instead of props to access user information
        if (userDetails !== null ) {

        // Perform navigation based on the fetched data
        if ((userDetails.activeAssessment === -1 && userDetails.activeComponent === -1) || userDetails.activeCriteria === -1) {
            navigate(`/${props.userApplication}/complete/${params.userId}`);
        } else if (props.userApplication === 'maturity-assessment') {
            if (userDetails.activeCriteria > 1 && Object.values(rolesConfig['roles']).map(r => r.role).includes(userDetails.activeRole)) {
                navigate(`/${props.userApplication}/questions/${params.userId}`);
            } else {
                navigate(`/${props.userApplication}/roles/${params.userId}`);
            }
        } else if (userDetails.activeAssessment >= 0) {
            navigate(`/${props.userApplication}/questions/${params.userId}`);
        }
    }
    }, [userDetails]);

    const submitHandler = async (event) => {
        event.preventDefault();
        const applicationType = event.currentTarget.getAttribute('applicationType');

        await props.handleUserActiveApplicationUpdate(params.userId, applicationType);

        setButtonClicked(true)

    };


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={12} sx={{ minHeight: "20px" }}></Grid>
            <Grid item xs={12} md={10}>
                <Typography sx={{ fontSize: "22px", marginBottom: "8px" }}>You have been assigned one or more assessments to complete by your admin.</Typography>
                <Typography sx={{ fontSize: "22px", marginBottom: "8px" }}>Please click on the buttons below to complete the assessments.</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
                {
                    props.userRegisteredApplicationsList.map((applicationType) => {
                        let label = 'Start';
                        let isComplete = false;
                        try {
                            if (props.userCompleteApplicationsList.includes(applicationType)) {
                                label = 'Complete';
                                isComplete = true;
                            } else if (props.userActiveApplicationsList.includes(applicationType)) {
                                label = 'Continue';
                            }
                        } catch (error) {
                            console.error('Error checking includes:', error);
                        }

                        // Get the user-friendly display label for the application
                        const displayLabel = assessmentLabels[applicationType] || applicationType;

                        return (
                            <Button
                                key={applicationType}
                                applicationType={applicationType}
                                color="secondary"
                                type="submit"
                                onClick={submitHandler}
                                variant="outlined"
                                style={{ minWidth: '100%', margin: '5px 0', padding: '10px' }}
                                disabled={isComplete}
                            >
                                {label} {displayLabel} {/* User-friendly name */}
                            </Button>
                        );
                    })
                }
            </Grid>
        </Grid>
    );
}

export default Welcome;