import { RadioGroup, TextField, Stack, List, ListItem, ListItemAvatar, Avatar, ListItemText, FormControlLabel, Radio, Typography, Grid, Button, Container } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axiosInstance from '../../services/axiosInstance';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Question = (props) => {

    const navigate = useNavigate();
    const params = useParams();

    const [formIsValid, setFormIsValid] = useState(false);
    const [answers, setAnswers] = useState({});
    const [followupAnswers, setFollowupAnswers] = useState({});
    const [assessmentIndex, setAssessmentIndex] = useState(0);
    const [componentIndex, setComponentIndex] = useState(0);
    const [questionsUniqueAssessments, setQuestionsUniqueAssessments] = useState([]);
    const [questionsUniqueComponentsPerAssessment, setQuestionsUniqueComponentsPerAssessment] = useState([]);
    const [questionsFilterAssessmentAndComponent, setQuestionsFilterAssessmentAndComponent] = useState([]);
    const [questionIndex, setQuestionIndex] = useState(0);

    const fetchQuestionsUniqueAssessments = async () => {
        try {
            const response = await axiosInstance.get(`/questions/${params.application}/questionsUniqueAssessment`);
            setQuestionsUniqueAssessments(response.data);
        } catch (error) {
            console.error(`There was an error retrieving the assessment list: ${error}`);
        }
    };

    const fetchQuestionsUniqueComponentsPerAssessment = async (assessmentValue) => {
        try {
            const response = await axiosInstance.get(`/questions/${params.application}/questionsUniqueComponentPerAssessment/${assessmentValue}`);
            setQuestionsUniqueComponentsPerAssessment(response.data);
        } catch (error) {
            console.error(`There was an error retrieving the component list: ${error}`);
        }
    };

    const fetchQuestionsFilterAssessmentAndComponent = async (assessment, component) => {
        try {
            const response = await axiosInstance.get(`/questions/${params.application}/questionsFilterAssessmentAndComponent/${assessment}/${component}`);
            if (response) {
                setQuestionsFilterAssessmentAndComponent(response.data);
            }
        } catch (error) {
            console.error(`There was an error retrieving the questions list: ${error}`);
        }
    };

    useEffect(() => {
        fetchQuestionsUniqueAssessments();
    }, []);

    useEffect(() => {
        if (questionsFilterAssessmentAndComponent.length > 0 && questionIndex >= 0) {
        }
    }, [questionsFilterAssessmentAndComponent, questionIndex]);

    useEffect(() => {
        props.fetchUserActiveAssessment(params.userId);
        if (typeof props.userActiveAssessment === 'number') {
            setAssessmentIndex(props.userActiveAssessment);
        }
    }, [props.userActiveAssessment, props, params.userId]);

    useEffect(() => {
        const fetchComponent = async () => {
            await props.fetchUserActiveComponent(params.userId);
            if (typeof props.userActiveComponent === 'number') {
                setComponentIndex(props.userActiveComponent);
            }
        };
        fetchComponent();
    }, [props.userActiveComponent, props, params.userId]);

    useEffect(() => {
        if (questionsUniqueAssessments.length > 0 && assessmentIndex >= 0 && assessmentIndex < questionsUniqueAssessments.length) {
            fetchQuestionsUniqueComponentsPerAssessment(questionsUniqueAssessments[assessmentIndex].assessment);
        }
    }, [questionsUniqueAssessments, assessmentIndex]);

    useEffect(() => {
        if (
            assessmentIndex >= 0 &&
            componentIndex >= 0 &&
            questionsUniqueAssessments.length > 0 &&
            questionsUniqueComponentsPerAssessment.length > 0 &&
            assessmentIndex < questionsUniqueAssessments.length &&
            componentIndex < questionsUniqueComponentsPerAssessment.length
        ) {
            fetchQuestionsFilterAssessmentAndComponent(
                questionsUniqueAssessments[assessmentIndex].assessment,
                questionsUniqueComponentsPerAssessment[componentIndex].component
            );
        }
    }, [questionsUniqueAssessments, questionsUniqueComponentsPerAssessment, assessmentIndex, componentIndex]);

    useEffect(() => {
        if (questionsFilterAssessmentAndComponent.length > 0) {
            setAnswers(
                questionsFilterAssessmentAndComponent.map((question) => question.questionId).reduce((acc, curr) => {
                    acc[curr] = "unanswered";
                    return acc;
                }, {})
            );
        }
    }, [questionsFilterAssessmentAndComponent]);

    useEffect(() => {
        if (questionsFilterAssessmentAndComponent.length > 0) {
            setFollowupAnswers(
                questionsFilterAssessmentAndComponent.map((question) => question.questionId).reduce((acc, curr) => {
                    acc[curr] = "unanswered";
                    return acc;
                }, {})
            );
        }
    }, [questionsFilterAssessmentAndComponent]);

    useEffect(() => {
        if (Object.keys(followupAnswers).length > 0) {
            console.log("followupAnswers: ", followupAnswers);
        }
    }, [followupAnswers]);

    const handleChange = (event) => {
        setAnswers({
            ...answers,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeFollowup = (event) => {
        setFollowupAnswers({
            ...followupAnswers,
            [event.target.name]: event.target.value,
        });
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setFormIsValid(
                Object.keys(answers).length > 0 && !Object.values(answers).includes('unanswered')
            );
            const unansweredCount = Object.values(answers).filter(v => v === "unanswered").length;
            if (Object.keys(answers).length > 0 && unansweredCount >= 1) {
                setQuestionIndex(Object.keys(answers).length - unansweredCount);
            }
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [answers]);

    const submitHandler = async (event) => {
        event.preventDefault();
        try {
            const createPromises = Object.entries(answers).map(([id, value]) =>
                props.handleAnswerCreate(id, params.userId, value, followupAnswers[id]?.toString(),undefined)
            );
            await Promise.all(createPromises);

            if (componentIndex < questionsUniqueComponentsPerAssessment.length - 1) {
                await props.handleUserActiveComponentUpdate(params.userId, componentIndex + 1);
                setComponentIndex(prev => prev + 1);
            } else if (assessmentIndex < questionsUniqueAssessments.length - 1) {
                await props.handleUserActiveAssessmentUpdate(params.userId, assessmentIndex + 1);
                await props.handleUserActiveComponentUpdate(params.userId, 0);
                setComponentIndex(0);
                setAssessmentIndex(prev => prev + 1);
            } else if (
                assessmentIndex === questionsUniqueAssessments.length - 1 &&
                componentIndex === questionsUniqueComponentsPerAssessment.length - 1
            ) {
                await props.handleUserActiveAssessmentUpdate(params.userId, -1);
                await props.handleUserActiveComponentUpdate(params.userId, -1);
                setAssessmentIndex(-1);
                setComponentIndex(-1);
                console.log("APPLICATION IS COMPLETE");

                navigate(`/${params.application}/complete/${params.userId}`);
            }
            setQuestionIndex(0);
            setFormIsValid(false);
        } catch (error) {
            console.error('Error during submission:', error);
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={12} sx={{ minHeight: "20px" }}></Grid>
            <Grid item xs={12} md={10}>
                {questionsUniqueAssessments.length >= 1 && assessmentIndex >= 0 && assessmentIndex < questionsUniqueAssessments.length
                    ?
                <Typography variant="h4">{questionsUniqueAssessments[assessmentIndex].assessment.replace("|", " and ")}</Typography>
                    : <></>}
            </Grid>
            <Grid item xs={12} md={10}>
                {questionsUniqueComponentsPerAssessment.length > 0 && componentIndex >= 0 && componentIndex < questionsUniqueComponentsPerAssessment.length
                    ?
                <Typography variant="h5">{questionsUniqueComponentsPerAssessment[componentIndex].component.replace("|", " and ")}</Typography>
                    : <></>}
            </Grid>
            <Grid item xs={12} md={10}>
                {
                questionsFilterAssessmentAndComponent.length >= 1
                   ?
                <List style={{maxHeight: '550px', overflow: 'auto', width:"100%"}}>
                        {questionsFilterAssessmentAndComponent.map((question) => (
                            <ListItem key={question.questionId}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    rowSpacing={{ xs: 2, md: 2 }}
                                    columnSpacing={{ xs: 1, md: 1 }}
                                >
                                    <Grid item xs={1} md={1}>
                                        <ListItemAvatar>
                                            <Avatar style={{backgroundColor: "#612411"}}>
                                                <ArrowForwardIosIcon/>
                                            </Avatar>
                                        </ListItemAvatar>
                                    </Grid>
                                    <Grid item xs={11} md={11}>
                                        <ListItemText
                                            disableTypography
                                            primary={ <Typography style={{fontWeight: "bold"}}>{question.question}</Typography>}/>
                                    </Grid>
                                    <Grid item xs={10} md={10}>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name={question.questionId.toString()}
                                            onChange={handleChange}
                                            value={answers[question.questionId.toString()] || ''}
                                            style={{minWidth:'150px', marginLeft:"10px"}}
                                        >
                                            <FormControlLabel
                                                value="Strongly Disagree"
                                                control={<Radio color="secondary"/>}
                                                name={question.questionId.toString()}
                                                checked={answers[question.questionId.toString()] === 'Strongly Disagree'}
                                                label="Strongly Disagree"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="Disagree"
                                                control={<Radio color="secondary"/>}
                                                name={question.questionId.toString()}
                                                checked={answers[question.questionId.toString()] === 'Disagree'}
                                                label="Disagree"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="Neutral"
                                                control={<Radio color="secondary"/>}
                                                name={question.questionId.toString()}
                                                checked={answers[question.questionId.toString()] === 'Neutral'}
                                                label="Neutral"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="Agree"
                                                control={<Radio color="secondary"/>}
                                                name={question.questionId.toString()}
                                                label="Agree"
                                                checked={answers[question.questionId.toString()] === 'Agree'}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="Strongly Agree"
                                                control={<Radio color="secondary"/>}
                                                name={question.questionId.toString()}
                                                label="Strongly Agree"
                                                checked={answers[question.questionId.toString()] === 'Strongly Agree'}
                                                labelPlacement="end"
                                            />
                                              <FormControlLabel
                                                value="I don't know"
                                                control={<Radio color="secondary"/>}
                                                name={question.questionId.toString()}
                                                checked={answers[question.questionId.toString()] === "I don't know"}
                                                label="I don't know"
                                                labelPlacement="end"
                                              />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={10} md={10}>

                                    {answers[question.questionId.toString()] !== 'unanswered'
                                      ? (
                                        <Grid>
                                          {question.followupquestion !== 'nan'
                                           && question.followupflag.split('|').includes(answers[question.questionId.toString()])
                                            ? (
                                              <Grid item xs={10} md={10}>
                                                <ListItemText
                                                  disableTypography
                                                  primary={
                                                    <Typography style={{fontWeight: "bold"}}>
                                                      {question.followupquestion}
                                                    </Typography>
                                                  }
                                                />
                                              </Grid>
                                            ) : (
                                              <Grid item xs={10} md={10}>
                                                <ListItemText
                                                  disableTypography
                                                  primary={
                                                    <Typography style={{fontWeight: "bold"}}>
                                                      Please provide extra detail (if applicable)
                                                    </Typography>
                                                  }
                                                />
                                              </Grid>
                                            )
                                          }
                                        </Grid>
                                      ) : <Grid></Grid>
                                    }

                                    </Grid>
                                    {answers[question.questionId.toString()] !== 'unanswered'
                                        ?
                                    <Grid item xs={10} md={10}>
                                        <TextField
                                            name={question.questionId.toString()}
                                            onChange={handleChangeFollowup}
                                            multiline={true}
                                            sx={{width:"90%"}}
                                            label="Enter your comments:"
                                            color="secondary"
                                            focused
                                        />
                                    </Grid>
                                        :
                                    <Grid></Grid>
                                    }

                                </Grid>
                            </ListItem>
                        ))}
                    </List>

                : <></>
                }
            </Grid>
            <Grid item xs={12} md={10}>
                <Button
                    color="secondary"
                    type="button"
                    onClick={submitHandler}
                    disabled={!formIsValid}
                    variant="outlined"
                    style={{ minWidth: '100%', margin: '5px 0px 5px 0px', padding: '10px' }}
                >
                    {(assessmentIndex === questionsUniqueAssessments.length-1 && componentIndex === questionsUniqueComponentsPerAssessment.length-1) ? 'Finish Assessment' : 'Continue'}
                </Button>
            </Grid>
        </Grid>
    );
}

export default Question;
