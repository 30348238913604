import { RadioGroup, TextField, Stack, List, ListItem, ListItemAvatar, Avatar, ListItemText, FormControlLabel, Radio, Typography, Grid, Button, Container } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axiosInstance from '../../services/axiosInstance';
import {useParams} from "react-router-dom";
import { useNavigate } from "react-router-dom";


const QuestionPlatformSolution = (props) => {

    const navigate = useNavigate();
    const params = useParams()

    const backendIp = window.location.hostname
    const protocol = 'http' //window.location.protocol.includes('https') ? 'https' : 'http';
    const backendPort = 4003

    const [formIsValid, setFormIsValid] = useState(false);

    const [answers, setAnswers] = useState({});
    const [assessmentIndex, setAssessmentIndex] = useState(0)
    const [componentIndex, setComponentIndex] = useState(0)
    const [questionsUniqueAssessments, setQuestionsUniqueAssessments] = useState([])
    const [questionsUniqueComponentsPerAssessment, setQuestionsUniqueComponentsPerAssessment] = useState([])
    const [questionsFilterAssessmentAndComponent, setQuestionsFilterAssessmentAndComponent] = useState([])
    const [displayed, setDisplayed] = useState({})
    const [questionIndex, setQuestionIndex] = useState(0)

    const fetchQuestionsUniqueAssessments = async () => {
      try {
        const response = await axiosInstance.get(`/questions/${params.application}/questionsUniqueAssessmentFilterForSpecificUser/${params.userId}`);
        setQuestionsUniqueAssessments(response.data);
      } catch (error) {
        console.error(`There was an error retrieving the assessment list: ${error}`);
      }
    };

    const fetchQuestionsUniqueComponentsPerAssessment = async (assessmentValue) => {
      try {
        const response = await axiosInstance.get(`/questions/${params.application}/questionsUniqueComponentPerAssessment/${assessmentValue}`);
        setQuestionsUniqueComponentsPerAssessment(response.data);
      } catch (error) {
        console.error(`There was an error retrieving the component list: ${error}`);
      }
    };

    const fetchQuestionsFilterAssessmentAndComponent = async (assessment, component) => {
      try {
        const response = await axiosInstance.get(`/questions/${params.application}/questionsFilterAssessmentAndComponent/${assessment}/${component}`);
        if (response) {
          setQuestionsFilterAssessmentAndComponent(response.data);
        }
      } catch (error) {
        console.error(`There was an error retrieving the questions list: ${error}`);
      }
    };

    useEffect(() => {
        fetchQuestionsUniqueAssessments()
    }, [])

    useEffect(() => {
        if (
          typeof questionIndex !== "undefined" &&
          typeof questionsFilterAssessmentAndComponent !== "undefined" &&
          questionsFilterAssessmentAndComponent.length > 0 &&
          questionIndex >= 0
        ) {
            setDisplayed(questionsFilterAssessmentAndComponent[questionIndex])
        }
    }, [questionsFilterAssessmentAndComponent, questionIndex])

    useEffect(() => {
        props.fetchUserActiveAssessment(params.userId)
        // Make sure userActiveAssessment is a valid number before setting
        if (typeof props.userActiveAssessment === 'number') {
          setAssessmentIndex(props.userActiveAssessment)
        }
    }, [props, params.userId])

    useEffect(() => {
        props.fetchUserActiveComponent(params.userId)
        // Make sure userActiveComponent is a valid number before setting
        if (typeof props.userActiveComponent === 'number') {
          setComponentIndex(props.userActiveComponent)
        }
    }, [props, params.userId])

    useEffect(() => {
        if (
          questionsUniqueAssessments.length > 0 &&
          assessmentIndex >= 0 &&
          assessmentIndex < questionsUniqueAssessments.length
        ) {
            fetchQuestionsUniqueComponentsPerAssessment(questionsUniqueAssessments[assessmentIndex].assessment)
        }
    }, [questionsUniqueAssessments, assessmentIndex])

    useEffect(() => {
        if (questionsUniqueComponentsPerAssessment.length > 0) {
            console.log("Component list: ", questionsUniqueComponentsPerAssessment)
        }
    }, [questionsUniqueComponentsPerAssessment])

    useEffect(() => {
        if (
          assessmentIndex >= 0 &&
          componentIndex >= 0 &&
          questionsUniqueAssessments.length > 0 &&
          questionsUniqueComponentsPerAssessment.length > 0 &&
          assessmentIndex < questionsUniqueAssessments.length &&
          componentIndex < questionsUniqueComponentsPerAssessment.length
        ) {
            fetchQuestionsFilterAssessmentAndComponent(
                questionsUniqueAssessments[assessmentIndex].assessment,
                questionsUniqueComponentsPerAssessment[componentIndex].component
            )
        }
    }, [questionsUniqueAssessments, questionsUniqueComponentsPerAssessment, assessmentIndex, componentIndex])

    useEffect(() => {
        if (questionsFilterAssessmentAndComponent.length > 0) {
            console.log("questions: ", questionsFilterAssessmentAndComponent, questionsFilterAssessmentAndComponent.length)
        }
    }, [questionsFilterAssessmentAndComponent])

    useEffect(() => {
        if (questionsFilterAssessmentAndComponent.length > 0) {
            setAnswers(
                questionsFilterAssessmentAndComponent.map((question) => question.questionId).reduce((acc,curr)=> {
                    acc[curr]="unanswered"
                    return acc
                }, {} )
            )
        }
    }, [questionsFilterAssessmentAndComponent])

    useEffect(() => {
        if (Object.keys(answers).length > 0) {
            console.log("answers: ", answers)
        }
    }, [answers])

    const handleChange = (event) => {
        setAnswers({
            ...answers,
            [event.target.name]: event.target.value,
        });
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setFormIsValid(
                Object.keys(answers).length > 0 && !Object.values(answers).includes('unanswered')
            );
            if (Object.keys(answers).length > 0 && Object.keys(Object.values(answers).filter((v) => v === "unanswered")).length >= 1) {
                setQuestionIndex(Object.keys(answers).length - Object.keys(Object.values(answers).filter((v) => v === "unanswered")).length)
            }
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [answers]);

const submitHandler = (event) => {
  event.preventDefault();

  const handleSubmission = async () => {
    try {
      // Save answers
      for (const [id, value] of Object.entries(answers)) {
         props.handleAnswerCreate(
             id, // questionId
             params.userId, // userId
             value, // answer
             undefined, // followupAnswer
             undefined // role
         );

      }

      // Move to next component or assessment
      if (componentIndex < questionsUniqueComponentsPerAssessment.length - 1) {
        await props.handleUserActiveComponentUpdate(params.userId, componentIndex + 1);
        setComponentIndex((prev) => prev + 1);
      } else if (assessmentIndex < questionsUniqueAssessments.length - 1) {
        await props.handleUserActiveAssessmentUpdate(params.userId, assessmentIndex + 1);
        await props.handleUserActiveComponentUpdate(params.userId, 0);
        setComponentIndex(0);
        setAssessmentIndex((prev) => prev + 1);
      } else if (
        assessmentIndex === questionsUniqueAssessments.length - 1 &&
        componentIndex === questionsUniqueComponentsPerAssessment.length - 1
      ) {
        // Finish the assessment process
        await props.handleUserActiveAssessmentUpdate(params.userId, -1);
        await props.handleUserActiveComponentUpdate(params.userId, -1);
        setAssessmentIndex(-1);
        setComponentIndex(-1);

        navigate(`/${params.application}/complete/${params.userId}`);
      }

      // Reset states
      setQuestionIndex(0);
      setFormIsValid(false);
    } catch (error) {
      console.error('Error during submission:', error);
      // Optionally, handle the error gracefully (e.g., show a message to the user)
    }
  };
    handleSubmission();

  };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={12} sx={{ minHeight: "20px" }}></Grid>
            <Grid item xs={12} md={10}>{questionsUniqueAssessments.length >= 1 && assessmentIndex >= 0
                    ?
                <Typography variant="h4">{questionsUniqueAssessments[assessmentIndex].assessment.replace("|", " and ")}</Typography>
                : <></>}
            </Grid>
            <Grid item xs={12} md={10}>{questionsUniqueComponentsPerAssessment.length > 0 && componentIndex >= 0
                    ?
                <Typography variant="h5">{questionsUniqueComponentsPerAssessment[componentIndex].component.replace("|", " and ")}</Typography>
                : <></>}
            </Grid>
            <Grid item xs={12} md={10}>
                {
                questionsFilterAssessmentAndComponent.length >= 1
                   ?
                <List style={{maxHeight: '550px', overflow: 'auto', width:"100%"}}>
                        {questionsFilterAssessmentAndComponent.map((question) => {
                        {/* {Object.fromEntries(displayed).map((question) => {  */}
                            return (
                                <ListItem key={question.questionId}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        rowSpacing={{ xs: 2, md: 2 }}
                                        columnSpacing={{ xs: 1, md: 1 }}
                                    >
                                        <Grid item xs={1} md={1}>
                                            <ListItemAvatar>
                                                <Avatar style={{backgroundColor: "#612411"}}>
                                                    <ArrowForwardIosIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                        </Grid>
                                        <Grid item xs={11} md={11}>
                                            <ListItemText
                                                disableTypography
                                                primary={ <Typography style={{fontWeight: "bold"}}>{question.question}</Typography>}/>
                                        </Grid>
                                        <Grid item xs={10} md={10}>
                                            {
                                                question.questionType === "TEXT"
                                                    ?
                                                <Grid>
                                                    {}
                                                    <TextField
                                                        name={question.questionId.toString()}
                                                        onChange={handleChange}
                                                        multiline={true}
                                                        sx={{width:"90%"}}
                                                        label="Enter your comments:"
                                                        color="secondary"
                                                        focused
                                                    />
                                                </Grid>
                                                    :
                                                <Grid>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name={question.question}
                                                        key={question.questionId}
                                                        onChange={handleChange}
                                                        style={{minWidth:'150px', marginLeft:"10px"}}
                                                    >
                                                        <FormControlLabel
                                                            value="Yes"
                                                            control={<Radio color="secondary"/>}
                                                            name={question.questionId.toString()}
                                                            checked={answers[question.questionId.toString()] === 'Yes'}
                                                            label="Yes"
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value="No"
                                                            control={<Radio color="secondary"/>}
                                                            name={question.questionId.toString()}
                                                            checked={answers[question.questionId.toString()] === 'No'}
                                                            label="No"
                                                            labelPlacement="end"
                                                        />
                                                        {}
                                                    </RadioGroup>
                                                </Grid>

                                            }

                                        </Grid>
                                    </Grid>
                                </ListItem>
                            );
                        })}
                    </List>
                : <></>
                }
            </Grid>
            <Grid item xs={12} md={10}>
                <Button
                    color="secondary"
                    type="submit"
                    onClick={submitHandler}
                    disabled={!formIsValid}
                    variant="outlined"
                    style={{ minWidth: '100%', margin: '5px 0px 5px 0px', padding: '10px' }}
>
                    {(assessmentIndex === questionsUniqueAssessments.length-1 && componentIndex === questionsUniqueComponentsPerAssessment.length-1) ? 'Finish Assessment' : 'Continue'}
                    </Button>
            </Grid>
        </Grid>
    );
}

export default QuestionPlatformSolution;